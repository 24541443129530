import Head from "next/head";
import { SearchAction, WebSite, Organization } from "schema-dts";

import JsonLd from "components/JsonLd";
import favicon from "assets/images/favicon.ico";
import appleTouchIconDefault from "assets/images/apple-touch-icon-default.png";
import appleTouchIcon76 from "assets/images/apple-touch-icon-76x76.png";
import appleTouchIcon120 from "assets/images/apple-touch-icon-120x120.png";
import appleTouchIcon152 from "assets/images/apple-touch-icon-152x152.png";

const SocialMeta = () => (
  <Head>
    <meta property="og:site_name" content="The Atlantic" key="og:site_name" />
    <meta property="og:locale" content="en_US" key="og:locale" />
    <meta property="og:type" content="website" key="og:type" />
    <meta
      property="og:description"
      key="og:description"
      content="The Atlantic covers news, politics, culture, technology, health, and more, through its articles, podcasts, videos, and flagship magazine."
    />
    <meta
      property="og:image"
      key="og:image"
      content="https://cdn.theatlantic.com/assets/static/a/theatlantic/img/lacroix-default-thumbnail.png"
    />

    <meta property="fb:admins" content="577048155,17301937" key="fb:admins" />
    <meta property="fb:app_id" content="100770816677686" key="fb:app_id" />
    <meta
      property="fb:pages"
      key="fb:pages"
      content="29259828486,1468531833474495,1061579677251147,457711054591520,370457103090695,1631141167169115,148681772342453,1510507419185410,128344747344340,128377530562508,236061986423933"
    />

    <meta
      name="p:domain_verify"
      content="68e1a0361a557708fefc992f3309ed70"
      key="p:domain_verify"
    />

    <meta name="twitter:site" content="@theatlantic" key="twitter:site" />
    <meta
      name="twitter:domain"
      content="theatlantic.com"
      key="twitter:domain"
    />
  </Head>
);

// See https://github.com/google/schema-dts/issues/33
type QueryAction = SearchAction & {
  "query-input": string;
};

const potentialAction: QueryAction = {
  "@type": "SearchAction",
  target: "https://www.theatlantic.com/search/?q={q}",
  "query-input": "required name=q",
};

const SiteWideJsonLd = () => (
  <>
    <JsonLd<WebSite>
      item={{
        "@context": "https://schema.org",
        "@type": "WebSite",
        name: "The Atlantic",
        url: "https://www.theatlantic.com",
        inLanguage: "en-US",
        issn: "1072-7825",
        potentialAction,
      }}
      key="jsonld-website"
    />
    <JsonLd<Organization>
      item={{
        "@context": "https://schema.org",
        "@type": "Organization",
        "@id": "https://www.theatlantic.com/#publisher",
        name: "The Atlantic",
        url: "https://www.theatlantic.com",
        logo: {
          "@type": "ImageObject",
          width: {
            "@type": "QuantitativeValue",
            unitCode: "E37",
            value: 224,
          },
          height: {
            "@type": "QuantitativeValue",
            unitCode: "E37",
            value: 224,
          },
          url:
            "https://cdn.theatlantic.com/assets/media/files/atlantic-logo--224x224.png",
        },
        sameAs: [
          "https://www.facebook.com/TheAtlantic",
          "https://twitter.com/theatlantic",
        ],
      }}
      key="jsonld-org"
    />
  </>
);

const SiteMetadata = () => (
  <>
    <Head>
      <title>The Atlantic</title>
      <meta charSet="utf-8" key="charset" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1"
        key="viewport"
      />
      <meta
        name="description"
        key="description"
        content="The Atlantic covers news, politics, culture, technology, health, and more, through its articles, podcasts, videos, and flagship magazine."
      />
      <link rel="icon" key="favicon" href={favicon} />
      <link
        rel="apple-touch-icon"
        key="apple-touch-icon-default"
        href={appleTouchIconDefault}
      />
      <link
        rel="apple-touch-icon"
        key="apple-touch-icon-76x76"
        sizes="76x76"
        href={appleTouchIcon76}
      />
      <link
        rel="apple-touch-icon"
        key="apple-touch-icon-120x120"
        sizes="120x120"
        href={appleTouchIcon120}
      />
      <link
        rel="apple-touch-icon"
        key="apple-touch-icon-152x152"
        sizes="152x152"
        href={appleTouchIcon152}
      />
      <meta
        name="application-name"
        key="application-name"
        content="theatlantic"
      />
      <meta
        name="msapplication-TileColor"
        key="msapplication-TileColor"
        content="#FFFFFF"
      />
      <meta
        name="msapplication-TileImage"
        key="msapplication-TileImage"
        content={appleTouchIconDefault}
      />
    </Head>
    <SocialMeta />
    <SiteWideJsonLd />
  </>
);

export default SiteMetadata;
