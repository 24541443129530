import { AppProps } from "next/app";
import Router from "next/router";
import { useEffect } from "react";

import { loadAdsLibrary } from "utils/ads";
import { withAuthContext } from "utils/auth";
import { loadDataLayer, getDataLayer } from "utils/analytics";
import { pauseAdsEffects } from "utils/ads";
import SiteMetadata from "components/SiteMetadata";

// Global, application-wide css imports
import "normalize.css";
import "fonts.scss";
import "app.scss";

loadAdsLibrary();
loadDataLayer();

type WebVitalsMetric = {
  id: string;
  label: "web-vital" | "custom";
  name: string;
  startTime: number;
  value: number;
};

export const reportWebVitals = (metric: WebVitalsMetric) => {
  const dataLayer = getDataLayer<{
    event: "performanceMark";
    performance: WebVitalsMetric;
  }>();

  dataLayer.push({
    event: "performanceMark",
    performance: metric,
  });
};

const App = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    // Always stop the ads from running operations during routing
    Router.events.on("routeChangeStart", pauseAdsEffects);
    return () => {
      Router.events.off("routeChangeStart", pauseAdsEffects);
    };
  }, []);

  return (
    <>
      <SiteMetadata />
      <Component {...pageProps} />
    </>
  );
};

export default withAuthContext(App);
