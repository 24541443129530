import { Thing, WithContext } from "schema-dts";

type JsonValueScalar = string | boolean | number;
type JsonValue =
  | JsonValueScalar
  | Array<JsonValue>
  | { [key: string]: JsonValue };

/**
 * A replacer for JSON.stringify to strip JSON-LD of illegal HTML entities
 * per https://www.w3.org/TR/json-ld11/#restrictions-for-contents-of-json-ld-script-elements
 */
const safeJsonLdReplacer = (
  _: string,
  value: JsonValue
): JsonValue | undefined => {
  if (typeof value === "string") {
    return value
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&apos;");
  } else if (value === null) {
    return undefined;
  } else {
    return value;
  }
};

const JsonLd = <T extends Thing>({ item }: { item: WithContext<T> }) => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{
      __html: JSON.stringify(item, safeJsonLdReplacer),
    }}
  />
);

export default JsonLd;
